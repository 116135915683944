<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="状态:">
              <el-select v-model.trim="status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input v-model.trim="search_key" clearable size="medium" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getAccountList"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="account_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>

          <!-- <el-table-column label="备注" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.remark }}</p>
            </template>
          </el-table-column>-->
          <el-table-column label="邮箱" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.email }}</p>
            </template>
          </el-table-column>
          <el-table-column label="账号" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.username }}</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.created_at) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.updated_at) }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column label="最后修改时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.updated_at) }}
              </p>
            </template>
          </el-table-column>-->
          <!-- <el-table-column label="权重" width="160" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>-->
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="sysModelOnclick(scope.row)"
                type="text"
                >刷新权限
              </el-button>
            </template>
          </el-table-column>-->
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="showDialog(scope.row)" type="text">修改密码</el-button>
              <el-button style="color: #03aef9" @click="checkModelDetail(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
    <el-dialog
      width="500px"
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      v-if="dialogVisible"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" auto-complete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            v-model="form.confirmPassword"
            auto-complete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button class="getRandomPassword" @click="getRandomPassword(8)" type="primary">生成随机密码</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="handleSubmit"
        >{{ confirmButtonText }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AccountListView",
  data() {
    return {
      btnLoading: false,
      form: {
        id: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      rules: {
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "change" },
          { min: 6, message: "密码最少6位数", trigger: "change" }
        ],
        confirmPassword: [
          { required: true, message: "请再次输入新密码", trigger: "change" },
          { validator: this.checkConfirmPassword, trigger: "change" }
        ]
      },
      dialogVisible: false,
      loading: false,
      search_key: "",
      status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      account_list: []
    };
  },
  computed: {
    confirmButtonText() {
      return this.btnLoading ? "提交中" : "确认";
    }
  },
  created() {
    this.getAccountList();
  },
  methods: {
    showDialog(row) {
      this.form.id = row.id;
      this.form.email = row.email;
      this.form.username = row.username;
      this.form.newPassword = undefined;
      this.form.confirmPassword = undefined;
      this.dialogVisible = true;
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.btnLoading = true;
            let res = await this.$common.httpPost(
              "/api/user/admin/update/one/password",
              {
                user_id: this.form.id,
                password: this.$md5(this.form.confirmPassword)
              }
            );
            if (res.code === "1000000") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
            } else {
              this.$message.error(res.message || "修改密码失败");
            }
          } catch (error) {
            this.$message.error(error.message || "修改密码失败");
          } finally {
            this.btnLoading = false;
          }
        }
      });
    },
    checkConfirmPassword(rule, value, callback) {
      if (value !== this.form.newPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    getAccountList() {
      let _me = this;
      let params = {
        key: this.search_key,
        status: this.status,
        page_size: this.page_size,
        page_number: this.page_number,
        sort_fields: ""
      };
      this.loading = true;
      this.$common
        .httpPost("/api/user/admin/find/list", params)
        .then(res => {
          // console.log(res)
          // return
          _me.loading = false;
          if (res.code === "1000000") {
            _me.account_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id
          ? "account-detail-list?id=" + model.id
          : "account-detail-list";
      this.$router.push(path);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        user_id: model.id
      };
      this.$common
        .httpPost("/api/user/admin/delete/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getAccountList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getAccountList();
    },
    getRandomPassword(length) {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
      this.form.newPassword = this.form.confirmPassword = password;
    }
  }
};
</script>

<style scoped>
.getRandomPassword {
  float: left;
}
</style>