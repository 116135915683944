<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="账号详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModel()">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="account_model"
          :rules="rules"
          ref="account_model"
          label-width="100px"
        >
          <el-form-item label="邮箱:" prop="email">
            <el-input v-model.trim="account_model.email" :disabled="!!id"></el-input>
          </el-form-item>
          <el-form-item label="账号:" prop="username">
            <el-input v-model.trim="account_model.username" :disabled="!!id"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password" v-if="!id">
            <el-button class="getRandomPassword" @click="getRandomPassword(8)" type="primary">生成随机密码</el-button>
            <el-input v-model.trim="account_model.password" type="password" show-password clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model.trim="account_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="权重:">-->
          <!--            <el-input-number-->
          <!--                v-model.trim="account_model.number"-->
          <!--                :min="0"-->
          <!--                label="权重"-->
          <!--            ></el-input-number>-->
          <!--          </el-form-item>-->
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "AccountListDetailView",
  data() {
    return {
      id: this.$route.query.id,
      loading: false,
      auth_role_list: [],
      account_model: {
        id: "",
        username: "",
        number: 0,
        status: 2,
        email: "",
        user_id: "",
        password: ""
      },
      rules: {
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { min: 6, message: "长度大于 6 个字符", trigger: "blur" }
        ],
        username: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 6, message: "长度大于 6 个字符", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "长度大于 6 个字符", trigger: "blur" }
        ]
      }
    };
  },

  methods: {
    getAccountModel(id) {
      let _me = this;
      _me.loading = true;
      let params = {
        user_id: id
      };
      _me.$common
        .httpPost("/api/user/admin/find/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.account_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    saveModel() {
      let id = this.account_model.id;
      if (id !== undefined && id.length > 0) {
        this.updateModel();
      } else {
        this.newModel();
      }
    },
    updateModel() {
      let _me = this;
      let model = _me.account_model;
      let params = {
        user_id: model.id,
        username: model.username,
        status: model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/update/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    newModel() {
      let _me = this;
      let model = _me.account_model;
      let params = {
        username: model.username,
        email: model.email,
        password: _me.$md5(model.password),
        status: model.status,
        number: model.number
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/add/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
    getRandomPassword(length) {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
      this.account_model.password = password;
    }
  },
  created() {
    let id = this.$route.query.id;
    if (id !== undefined && id.length > 0) {
      this.getAccountModel(id);
    }
  }
};
</script>

<style scoped>
/* .head-info {
  margin-top: 10px;
   color: #ffffff;
} */
.getRandomPassword {
  margin-bottom: 20px;
}
</style>